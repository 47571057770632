<template>
  <div class="organization_profile">
    <div class="organization_profile__image preloader">
    </div>
    <div class="organization_profile__information">
      <p class="blue-text preloader"></p>
      <p class="blue-text preloader"></p>
      <span class="organization_profile__information__pointed preloader">
        <span class="organization_profile__information__pointed-number"
          ></span
        >
      </span>
        <p class="blue-text preloader"></p>
    </div>

  </div>
</template>
<script lang="ts" src="@/presentation/components/preloader/left-side-preloader/LeftSizePreloader.ts" scoped>
</script>

<style lang="scss" src="@/presentation/components/preloader/left-side-preloader/LeftSizePreloader.scss" scoped>
</style>
